@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slide-in {
  from {
    transform: translateX(-290px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-290px);
  }
}

.calc-width {
  width: calc((100vw - 1024px) / 2 - 4vw);
}

@layer utilities {
  .animate-slide-out {
    animation: slide-out 0.3s ease;
  }

  .animate-slide-in {
    animation: slide-in 0.3s ease;
  }
}
